var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{class:_vm.field.class,attrs:{"label-for":_vm.field.key}},_vm._l((_vm.value),function(condition,idx){return _c('b-row',{key:("condition-" + idx),staticClass:"dynamic-group"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? condition.lotteryName : condition.lotteryNameKh)+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":("payout-2d-" + idx)}},[_c('validation-provider',{attrs:{"vid":("payout-2d-" + idx),"name":_vm.$t('field.payoutTwoDigit'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.payoutTwoDigit"))+" ")]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"disabled":_vm.disabled,"placeholder":_vm.$t('field.payoutTwoDigit'),"name":("payout-2d-" + idx),"autocomplete":"off"},model:{value:(condition.payoutTwoDigit),callback:function ($$v) {_vm.$set(condition, "payoutTwoDigit", $$v)},expression:"condition.payoutTwoDigit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":("payout-3d-" + idx)}},[_c('validation-provider',{attrs:{"vid":("payout-3d-" + idx),"name":_vm.$t('field.payoutThreeDigit'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.payoutThreeDigit"))+" ")]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"disabled":_vm.disabled,"placeholder":_vm.$t('field.payoutThreeDigit'),"name":("payout-3d-" + idx),"autocomplete":"off"},model:{value:(condition.payoutThreeDigit),callback:function ($$v) {_vm.$set(condition, "payoutThreeDigit", $$v)},expression:"condition.payoutThreeDigit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":("comission-2d-" + idx)}},[_c('validation-provider',{attrs:{"vid":("comission-2d-" + idx),"name":_vm.$t('field.commissionPercentage2'),"rules":"required|decimal:2|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.commissionPercentage2"))+" ")]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"disabled":_vm.disabled,"placeholder":_vm.$t('field.commissionPercentage2'),"name":("comission-2d-" + idx),"autocomplete":"off"},model:{value:(condition.commissionNumTwo),callback:function ($$v) {_vm.$set(condition, "commissionNumTwo", $$v)},expression:"condition.commissionNumTwo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('field.commissionTypeTwo')}},[_c('validation-provider',{attrs:{"vid":("comission-type-two-" + idx),"name":_vm.$t('field.commissionTypeTwo'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"button-checkbox",attrs:{"disabled":_vm.disabled,"name":("comission-type-two-" + idx),"button-variant":"outline-primary","buttons":""},model:{value:(condition.commissionTypeTwo),callback:function ($$v) {_vm.$set(condition, "commissionTypeTwo", $$v)},expression:"condition.commissionTypeTwo"}},[_c('b-form-radio',{attrs:{"value":1,"buttons":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("field.includeCommission"))+" ")])]),_c('b-form-radio',{attrs:{"value":2,"buttons":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("field.excludeCommission"))+" ")])]),_c('b-form-radio',{attrs:{"value":3,"buttons":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("field.excludeCommissionCutFee"))+" ")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":("comission-3d-" + idx)}},[_c('validation-provider',{attrs:{"vid":("comission-3d-" + idx),"name":_vm.$t('field.commissionPercentage3'),"rules":"required|decimal:2|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.commissionPercentage3"))+" ")]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"disabled":_vm.disabled,"placeholder":_vm.$t('field.commissionPercentage3'),"name":("comission-3d-" + idx),"autocomplete":"off"},model:{value:(condition.commissionNumThree),callback:function ($$v) {_vm.$set(condition, "commissionNumThree", $$v)},expression:"condition.commissionNumThree"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('field.commissionTypeThree')}},[_c('validation-provider',{attrs:{"vid":("comission-type-three-" + idx),"name":_vm.$t('field.commissionTypeThree'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"button-checkbox",attrs:{"disabled":_vm.disabled,"name":("comission-type-three-" + idx),"button-variant":"outline-primary","buttons":""},model:{value:(condition.commissionTypeThree),callback:function ($$v) {_vm.$set(condition, "commissionTypeThree", $$v)},expression:"condition.commissionTypeThree"}},[_c('b-form-radio',{attrs:{"value":1,"buttons":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("field.includeCommission"))+" ")])]),_c('b-form-radio',{attrs:{"value":2,"buttons":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("field.excludeCommission"))+" ")])]),_c('b-form-radio',{attrs:{"value":3,"buttons":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("field.excludeCommissionCutFee"))+" ")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }