export default [
  {
    key: 'name',
    label: 'field.name',
    rules: 'required',
    type: 'text',
  },
  {
    key: 'phone',
    label: 'field.phoneNumber',
    rules: 'required',
    type: 'tel',
  },
  {
    key: 'ccy',
    label: 'field.ccy',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'currency',
    selectionKey: 'ccy',
    selectionLabel: 'currency',
  },
  {
    key: 'lotteryIds',
    label: 'field.lottery',
    rules: 'required',
    type: 'nAsynMultiSelection',
    repository: 'lottery',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'maxTodayBet',
    label: 'field.maxTodayBet',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'minBetInvoice',
    label: 'field.minBetInvoice',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetInvoice',
    label: 'field.maxBetInvoice',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGame',
    label: 'field.maxBetGame',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'minBetPerNumTwo',
    label: 'field.minBetPerNumTwo',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetPerNumTwo',
    label: 'field.maxBetPerNumTwo',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'minBetPerNumThree',
    label: 'field.minBetPerNumThree',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetPerNumThree',
    label: 'field.maxBetPerNumThree',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinGameNumTwo',
    label: 'field.maxWinGameNumTwo',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinGameNumThree',
    label: 'field.maxWinGameNumThree',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    label: 'field.lotterySetting',
    icon: 'SettingsIcon',
    type: 'divider',
  },
  {
    key: 'lotterySettings',
    label: 'field.lotterySetting',
    rules: '',
    type: '',
    cols: 12,
  },
  {
    label: 'breadcrumb.accountInfomration',
    icon: 'LockIcon',
    type: 'divider',
  },
  {
    key: 'username',
    label: 'field.username',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'required|min:6',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'required|confirmed:password',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'isReadonly',
    label: 'status.readonly',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
