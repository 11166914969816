<template>
  <b-row>
    <b-col
      :sm="
        field.type === 'divider'
          ? 12
          : field.cols
          ? field.cols
          : field.hideInView
          ? 0
          : 6
      "
      v-for="field in fields"
      :key="field.key"
    >
      <div v-if="!field.resource || $can(field.action, field.resource)">
        <slot :name="field.key" :field="field" v-if="!field.hideInView">
          <div class="d-flex mt-2" v-if="field.type === 'divider'">
            <feather-icon size="19" :icon="field.icon" v-if="field.icon" />
            <h4 class="ml-50">{{ $t(field.label) }}</h4>
          </div>
          <b-form-group
            :label-for="field.key"
            :description="getDesciption(field)"
            v-if="field.type !== 'divider'"
          >
            <label v-if="field.type !== 'checkbox'">{{
              $t(field.label)
            }}</label>
            <b-form-input
              v-if="field.type === 'text'"
              v-model="data[field.key]"
              :placeholder="$t(field.label)"
              :name="field.key"
              :disabled="true"
            />
            <n-currency-input
              v-if="field.type === 'currency'"
              v-model="data[field.key]"
              :id="field.key"
              :name="field.key"
              :placeholder="$t(field.label)"
              :disabled="true"
            ></n-currency-input>
            <n-tel-input
              v-if="field.type === 'tel'"
              v-model="data[field.key]"
              :id="field.key"
              :name="field.key"
              :placeholder="$t(field.label)"
              :disabled="true"
            ></n-tel-input>
            <b-form-radio-group
              v-if="field.type === 'radio'"
              :id="field.key"
              v-model="data[field.key]"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in field.options"
                :key="option.value"
                :disabled="true"
                >{{ $t(option.text) }}</b-form-radio
              >
            </b-form-radio-group>
            <b-form-checkbox
              v-if="field.type === 'checkbox'"
              :id="field.key"
              v-model="data[field.key]"
              :name="field.key"
              :value="true"
              :unchecked-value="false"
              :disabled="true"
            >
              {{ $t(field.label) }}
            </b-form-checkbox>
            <cg-n-single-image-uploader
              v-if="field.type === 'single-image'"
              :pw="field.pw"
              :ph="field.ph"
              :fullWidth="field.fullWidth"
              :image="initValue[field.initKey]"
              v-model="data[field.key]"
              :readonly="true"
            ></cg-n-single-image-uploader>
            <b-form-textarea
              v-if="field.type === 'textarea'"
              v-model="data[field.key]"
              :id="field.key"
              :placeholder="$t(field.label)"
              :rows="field.rows ? field.rows : 2"
              :max-rows="field.maxRows ? field.maxRows : 6"
              :readonly="true"
              :disabled="true"
            ></b-form-textarea>
            <n-multi-select
              v-if="field.type === 'NMultiSelection'"
              v-model="data[field.key]"
              :init-options="data[field.key]"
              :options="field.options"
              :readonly="true"
            ></n-multi-select>
            <n-async-multi-select
              v-if="field.type === 'nAsynMultiSelection'"
              v-model="data[field.key]"
              :init-options="data[field.key]"
              :repository="field.repository"
              :readonly="true"
            ></n-async-multi-select>
            <n-async-single-select
              v-if="field.type === 'nAsynSingleSelection'"
              v-model="data[field.key]"
              :init-options="data[field.key]"
              :repository="field.repository"
              :selection-key="field.selectionKey"
              :selection-label="field.selectionLabel"
              :readonly="true"
            ></n-async-single-select>
          </b-form-group>
        </slot>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormRadioGroup,
  BFormRadio,
  BBadge,
  BRow,
  BCol,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import NSingleImageUploader from "@/components/NSingleImageUploader";
import NMultiSelect from "@/components/NMultiSelect";
import NAsyncMultiSelect from "@/components/NAsyncMultiSelect";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NCurrencyInput from "@/components/NCurrencyInput";
import NTelInput from "@/components/NTelInput";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormRadioGroup,
    BFormRadio,
    NSingleImageUploader,
    BBadge,
    BRow,
    BCol,
    BFormTextarea,
    NAsyncMultiSelect,
    NAsyncSingleSelect,
    BFormCheckbox,
    NCurrencyInput,
    NTelInput,
    NMultiSelect,
  },
  props: {
    value: {},
    initValue: {
      type: Object,
      default: function () {
        return {};
      },
    },
    fields: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {},
  watch: {
    data: function (newValue, oldValue) {
      this.$emit("input", newValue);
    },
    initValue: function (newValue, oldValue) {
      this.data = {
        ...newValue,
      };
    },
  },
  mounted() {
    this.data = {
      ...this.value,
    };
  },
  methods: {
    getDesciption(field) {
      if (field.type === "single-image") {
        return this.$t("general.recommendDimension", {
          ph: field.ph,
          pw: field.pw,
        });
      }
      return "";
    },
    isOptional(rules) {
      return !rules.includes("required");
    },
  },
};
</script>

<style scoped>
</style>
